// import image
import nvidia from "./images/nvidia.svg";
import crusoe from "./images/crusoe.svg";
import brev from "./images/logo_dark_mode.png";
import brevdev from "./images/brevdev.png";

export default function Footer() {
  return (
    <footer
      className="absolute bottom-0 left-0 w-[100vw]"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-4 pt-8 sm:pt-8 lg:px-8 lg:pt-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-4">
            <div className="flex flex-row">
              <a href="https://brev.dev" target="_blank" rel="no_referrer">
                <img className="h-9" src={brev} alt="Brev.dev" />
              </a>
              <p className="mx-1 mt-2">&</p>
              <a href="https://nvidia.com" target="_blank" rel="no_referrer">
                <img className="h-10" src={nvidia} alt="nvidia" />
              </a>
            </div>
            <p className="text-sm leading-6 text-gray-600">
              Built by Brev.dev & Roboflow in San Francisco 🌁
            </p>
          </div>
        </div>
        {/* <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-500">
            This product is open sourced. Make contributions{" "}
            <a href="https://github.com/codingcabana/prompt-battle">here</a>.
          </p>
        </div> */}
      </div>
    </footer>
  );
}
export function AltFooter() {
  return (
    <div
      className="w-full flex flex-col justify-center items-center pt-8 sm:pt-8 lg:px-8 lg:pt-8"
      aria-labelledby="footer-heading"
    >
      <div className="flex flex-row items-center justify-center">
        <a href="https://ai.nvidia.com" target="_blank" rel="no_referrer">
          <img className="h-8" src={nvidia} alt="Nvidia" />
        </a>
        <p className="mx-2 mt-2 text-white">& </p>
        <a href="https://brev.dev" target="_blank" rel="no_referrer">
          <img className="h-12" src={brevdev} alt="Brev.dev" />
        </a>

        {/* <p className="mx-1 mt-2 mr-3 text-white">/ </p>
        <a href="https://nvidia.com" target="_blank" rel="no_referrer">
          <img className="h-10 " src={crusoe} alt="Nvidia" />
        </a> */}
      </div>
    </div>
  );
}
