import React from "react";
import "./App.css";
import lostEmpires from "./images/lost-empires.png";
import rolex from "./images/rolex.png";
import oldapple from "./images/old-apple.png";
import llamaapple from "./images/1984llama.png";
import nvidia from "./images/nvidia.png";

import llamaQRCode from "./images/qrcode.png";
import Footer, { AltFooter } from "./Footer";

function App() {
  return (
    <>
      <Banner />

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl">
          <div className="pt-8 margin-auto">
            <h1 className="text-6xl font-bold text-white">
              If you were moved by this 1984 ad...
            </h1>
            {/* Import an image from this folder */}
            <img
              src={llamaapple}
              alt="Lost Empires"
              className="w-[100%] h-[100%] mt-4"
            />
            <div className="w-[100%] h-[100%] mt-4 flex flex-row">
              <div className="text-center flex flex-col w-[50%] mr-1">
                <h1 className="text-5xl font-bold text-white text-left">
                  you'd deploy a NIM.
                </h1>
                <p className="text-left text-white mt-4 text-lg">
                  The most powerful foundational AI models are being built
                  closed source, with a hackathon every weekend focused on using
                  them.
                  <br />
                  <br />
                  Llama3 is released (400b parameter 🔜👀), but there's still
                  not enough folks deploying their own models.
                  {/* <span
                    className="underline text-cyan-500 font-bold cursor-pointer"
                    onClick={() =>
                      window.open("https://partiful.com/e/wEQZuDT27CvSb8bHycbW")
                    }
                  >
                    first hackathon in SF
                  </span> */}
                </p>
                <p className="text-left text-white text-lg mt-4">
                  NVIDIA NIMs are the easiest way to deploy OSS models and your
                  own fine-tunes. Learn everything you need to know about NIMs
                  from the brightest, and leave with a deployed Llama3 NIM.
                  Speakers include founders & team members from NVIDIA,
                  Brev.dev, AnyScale, Roboflow, Sourcegraph, and more!
                  <br></br>
                  <br></br>
                </p>
                <p className="text-left text-white text-lg mt-4 ">
                  5-10pm on May 22nd, 2024.
                </p>
                <p className="text-left text-white text-lg mt-4 font-bold">
                  Come to learn. Leave with a model 🤙
                  <a
                    className="text-cyan-500 font-bold underline"
                    href="https://partiful.com/e/wEQZuDT27CvSb8bHycbW"
                  >
                    Request an invite.
                  </a>
                </p>
              </div>
              <div className="w-[50%] ml-1">
                <div className="flex flex-col">
                  <img
                    src={llamaQRCode}
                    alt="Lost Empires"
                    className="w-[100%] mt-[10%]"
                  />
                  <span
                    className="text-center text-cyan-500 font-bold cursor-pointer"
                    onClick={() =>
                      window.open("https://partiful.com/e/wEQZuDT27CvSb8bHycbW")
                    }
                  >
                    (scan to request an invite)
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full h-full text-right text-white mt-10">
              HOSTED BY{" "}
              <span
                className="text-cyan-500 cursor-pointer"
                onClick={() => {
                  window.open("https://brev.dev");
                }}
              >
                BREV.DEV
              </span>
              •{" "}
              <span
                className="text-cyan-500 cursor-pointer"
                onClick={() => {
                  window.open("https://nvidia.com/");
                }}
              >
                NVIDIA
              </span>
              •{" "}
              <span
                className="text-cyan-500 cursor-pointer"
                onClick={() => {
                  window.open("https://www.solarissf.com/solaris-ai/root");
                }}
              >
                SOLARIS SF
              </span>
            </div>
            {/* <div className="w-full flex justify-end h-full text-right text-white ">
              Thank you
              <a
                href="https://nvidia.com"
                target="_blank"
                rel="no_referrer"
                className="mx-1"
              >
                <img className="h-6" src={nvidia} alt="Brev.dev" />
              </a>
              for sponsoring
            </div> */}
            <div className="w-full h-full text-right text-white">
              INTENSELY NON-REMOTE @ 1680 MISSION ST, SAN FRANCISCO, CA
            </div>
          </div>
        </div>
        <AltFooter />
        <div className="pb-3"></div>
      </div>
    </>
  );
}

export default App;

export function Banner() {
  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
          }}
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
          }}
        />
      </div>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-gray-900">
          <strong className="font-semibold">LLAMA 3 EDITION</strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          An evening dedicated to deploying Llama3 with NVIDIA NIMs
        </p>
        <a
          href="https://partiful.com/e/wEQZuDT27CvSb8bHycbW"
          target="_blank"
          rel="noopener noreferrer"
          className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          Register now <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
      <div className="flex flex-1 justify-end">
        {/* <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        > */}
        {/* <span className="sr-only">Dismiss</span> */}
        {/* <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true" /> */}
        {/* </button> */}
      </div>
    </div>
  );
}
